@mixin hero-bg-overlay {
    background-repeat: no-repeat;
    display: block;
    opacity: 1;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
}

.hero-bg {
    padding: 170px 0 100px;

    .hero-img {
        margin-top: 50px;
    }

    .hero-bg-overlay {
        @include hero-bg-overlay;
    }
}

.hero-title {
    font-weight: 800;
}

@media only screen and (max-width: 767px) {
    .hero-bg {
        padding: 120px 0 60px;

        .hero-img {
            margin-top: 30px;
        }
    }
}