.counter-box {
    margin-top: 30px;
    border: 1px solid #fff;
    border-radius: 10px;
    padding: 30px 20px 20px;
    background: rgba($white, 0.05);
    backdrop-filter: blur(5px);
    transition: all 0.5s;

    .counter-icon {
        background: $primary2;
        height: 90px;
        width: 90px;
        border-radius: 50%;
        line-height: 90px;
        text-align: center;
        font-size: 46px;
        margin-bottom: 20px;
        transition: all 0.3s;
    }

    h5 {
        font-size: 18px;
    }

    &:hover {
        background: $white;

        .counter-icon {
            transform: translateY(-10px);
        }

        h5 {
            color: $dark;
        }
    }
}

@media only screen and (max-width: 767px) {
    .counter-box {
        margin-top: 20px;
        background: $white;

        h5 {
            color: $dark;
        }
    }
}