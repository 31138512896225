@import url('https://fonts.googleapis.com/css2?family=Jost:wght@100..900&display=swap');

$font-primary: 'Jost', sans-serif;
$font-secondary: 'Jost', sans-serif;

$white: #ffffff;
$black: #000000;

$primary: #282d6a;
$primary2: #ffc209;
$secondary: #6c757d;
$success: #15d68c;
$info: #59dbff;
$warning: #ff8057;
$danger: #ee4a4a;
$dark: #343a40;
$muted: #606670;
$light: #f7f9fe;
$body-bg: #ffffff;

$theme-colors: (
    "primary": $primary,
    "primary2": $primary2,
    "secondary": $secondary,
    "success": $success,
    "info": $info,
    "warning": $warning,
    "danger": $danger,
    "dark": $dark,
    "muted": $muted,
    "light": $light,
    "body-bg": $body-bg,
    "black": $black,
    "white": $white
);