.custom-form {
    background: $white;
    padding: 40px;
    overflow: hidden;
}

.contact-form {
    margin-bottom: -98px;

    &.hero {
        margin-bottom: -98px;
    }
}

.faq-box .accordion {
    border: 0px;
    background: $white;
}

.faq-box .accordion__button {
    outline: none !important;
    background: $white;
    color: $dark;

    &:hover {
        background: $white;
    }
}

.faq-box .accordion__panel {
    padding: 0px 30px 20px 30px;

    & p {
        margin: 0px;
    }
}

@media only screen and (max-width: 767px) {
    .custom-form {
        margin-top: 40px;
    }

    .contact-form {
        margin-bottom: -128px;

        &.hero {
            margin-bottom: -128px;
        }
    }
}