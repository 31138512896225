.service-box {
    background: rgba($primary, 0.03);
    border-radius: 10px;
    padding: 25px 20px;
    margin-bottom: 30px;
    position: relative;
    overflow: hidden;
    transition: all 0.5s;
    z-index: 0;

    .service-icon {
        position: relative;
        left: 10px;
        font-size: 44px;
        transition: all 0.5s;
    }

    .read-more-icon {
        .right-icon {
            font-size: 30px !important;
            transition: all 0.5s;
        }
    }

    h5 {
        transition: all 0.4s;
    }

    ul {
        padding-left: 20px;
    }

    &:before {
        content: "";
        height: 100px;
        width: 100px;
        position: absolute;
        top: 0px;
        left: 0px;
        border-radius: 10%;
        z-index: -1;
        transition: all 0.5s;
    }

    &:after {
        content: "";
        height: 0px;
        width: 0px;
        position: absolute;
        top: 0px;
        left: 0px;
        border-radius: 10%;
        z-index: -1;
        transition: all 0.5s;
    }

    &:hover {
        .service-icon {
            color: $white;
        }

        .service-title {
            color: $white !important;
        }

        .right-icon {
            color: $white;
        }

        .service-subtitle {
            color: rgba($white, 0.8) !important;
        }

        &:before {
            height: 100%;
            width: 100%;
            position: absolute;
            bottom: 0;
            right: 0;
            top: 0;
            left: 0;
            border-radius: 0;
        }

        &:after {
            background: rgba($white, 0.06);
            height: 100px;
            width: 100px;
        }
    }
}

.service-box-2 {
    background: $white;
    border-radius: 10px;
    padding: 30px;
    position: relative;
    overflow: hidden;
    transition: all 0.5s;

    ul {
        padding-left: 20px;
    }

    &:hover {
        background: $primary;

        h5 {
            color: $white !important;
        }

        .service-subtitle {
            color: rgba($white, 0.8) !important;
        }
    }
}

.servicesBlock {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
}

.servicesBox {
    display: flex;
    align-items: center;
    background: rgba($primary, 0.03);
    border-radius: 10px;
    padding-right: 20px;
    position: relative;
    overflow: hidden;
    cursor: default;
    flex: 0 0 32%;
    transition: all 0.5s;

    &:before {
        content: "";
        position: absolute;
        top: 0px;
        left: auto;
        right: 0px;
        width: 0;
        height: 100%;
        background: $primary;
        z-index: -1;
        transition: all 0.5s;
    }

    h5 {
        font-size: 18px;
        margin-bottom: 0px;
        transition: all 0.5s;
    }

    & .iconBox {
        background: rgba($primary, 0.05);
        color: $primary;
        font-size: 40px;
        width: 80px;
        height: 70px;
        line-height: 75px;
        text-align: center;
        border-radius: 10px;
        margin-right: 20px;
        flex: 0 0 80px;
        transition: all 0.5s;
    }

    &:hover {
        &:before {
            left: 0px;
            right: auto;
            width: 100%;
        }

        h5 {
            color: $white;
        }

        .iconBox {
            background: rgba($white, 0.08);
            color: $white;
        }
    }
}

@each $color,
$value in $theme-colors {
    .service-#{$color} {

        .service-icon,
        .right-icon {
            color: #{$value};
        }

        &:before {
            background: rgba($value, 0.05);
        }

        &:hover {
            &:before {
                background: #{$value};
            }
        }
    }
}

@media only screen and (max-width: 767px) {
    .service-box {
        &.active {
            .service-icon {
                color: $white;
            }

            .service-title {
                color: $white !important;
            }

            .right-icon {
                color: $white;
            }

            .service-subtitle {
                color: rgba($white, 0.8) !important;
            }

            &:before {
                height: 100%;
                width: 100%;
                position: absolute;
                bottom: 0;
                right: 0;
                top: 0;
                left: 0;
                border-radius: 0;
                background: $primary;
            }

            &:after {
                background: rgba($white, 0.06);
                height: 100px;
                width: 100px;
            }
        }
    }

    .service-box-2 {
        margin-bottom: 30px;
    }

    .servicesBlock {
        flex-direction: column;
    }
}