@each $color, $value in $theme-colors {
    .text-#{$color} {
        color: #{$value} !important;
    }

    .bg-#{$color} {
        background-color: #{$value} !important;
    }
}

.text-white-70 {
    color: rgba($white, 0.70);
}

.bg-gradient-primary {
    background: linear-gradient(-45deg, $primary 0%, $primary 100%);
}

.section {
    padding-top: 100px;
    padding-bottom: 100px;
    position: relative;
}

.bg-overlay {
    background: $black;
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;

    &.op-75 {
        opacity: 0.75;
    }

    &.op-85 {
        opacity: 0.85;
    }
}

.font-weight-medium {
    font-weight: 600;
}

.font-weight-800 {
    font-weight: 800;
}

.f-18 {
    font-size: 18px;
}

.f-50 {
    font-size: 50px;
}

.f-60 {
    font-size: 60px;
}

@media only screen and (max-width: 767px) {
    .section {
        padding-top: 60px;
        padding-bottom: 60px;
    }

    .f-50 {
        font-size: 30px;
    }

    .f-60 {
        font-size: 40px;
    }
}