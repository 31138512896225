.features-box {
    background: $black;
    border-radius: 10px;
    padding: 70px 40px 40px;
    margin-top: 50px;
    transition: all 0.5s;

    .features-icon {
        background: $primary2;
        display: inline-block;
        height: 50px;
        width: 100px;
        line-height: 50px;
        text-align: center;
        color: $white;
        border-radius: 6px;
        font-size: 18px;
        position: relative;
        margin-bottom: 25px;

        &:before {
            content: "";
            height: 60px;
            width: 100px;
            background: rgba($primary2, 0.15);
            position: absolute;
            left: 7px;
            top: -16px;
            transform: rotate(20deg);
            z-index: 0;
            border-radius: 6px;
        }
    }

    h5 {
        color: $white;
        font-size: 30px;
        margin-bottom: 0px;
    }

    &:hover {
        background: $primary;
    }
}

@media only screen and (max-width: 767px) {
    .features-box {
        padding: 60px 30px 30px;
        margin-top: 20px;

        &.first {
            margin-top: 0px;
        }

        h5 {
            font-size: 24px;
        }
    }
}