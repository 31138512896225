a {
    text-decoration: none !important;
    outline: none;
}

html {
    overflow-x: hidden;
}

body {
    font-family: $font-primary;
    color: $dark;
    background: $body-bg;
    font-size: 16px;
    overflow-x: hidden;
}

p {
    line-height: 1.6;
}

::selection {
    background: $primary2;
    color: $dark;
}

::-moz-selection {
    background: $primary2;
    color: $dark;
}

::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
    background-color: $light;
    border-radius: 10px;
}

::-webkit-scrollbar {
    width: 5px;
    height: 5px;
    background-color: $light;
}

::-webkit-scrollbar-thumb {
    background-color: $primary2;
    border-radius: 10px;
}